import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {useRecordContext} from 'components/record/useRecordContext';
import React from 'react';

type ReportType = {
  name: string;
  answers: {[id: number]: number};
};

const ReportPreview = () => {
  const {record} = useRecordContext();

  const questions: {id: number; question: string}[] = React.useMemo(() => record.questions, [record]);
  const reports: ReportType[] = React.useMemo(() => {
    const userNames: {[key: string]: string} = record.answers.reduce(
      (acc: {[key: string]: string}, answer: {name: string}) => ({...acc, [answer.name]: answer.name}),
      {}
    );
    return Object.values(userNames).map((name: string) => {
      const userAnswers: {id: number; questionId: number; value: number}[] = record.answers.filter((answer: {name: string}) => answer.name === name);
      const answers = userAnswers.reduce((acc, answer) => ({...acc, [answer.questionId]: answer.value}), {});
      return {name, answers};
    });
  }, [record]);

  return (
    <div>
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table sx={{width: '100%'}} size="small">
          <TableHead>
            <TableCell align={'left'}>Name</TableCell>
            {questions.map((item) => (
              <TableCell key={item.id}>{item.question}</TableCell>
            ))}
          </TableHead>

          <TableBody>
            {reports?.map((report) => (
              <TableRow key={report.name}>
                <TableCell>{report.name}</TableCell>
                {questions.map((item) => (
                  <TableCell key={item.id}>{report.answers[item.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportPreview;
