import {useDataProvider} from 'provider/DataProvider';
import {useEffect, useMemo, useState} from 'react';

const useFetchListData = <T,>(
  resource: string,
  params?: {[key: string]: string | number},
  time?: number
): {data?: {items: T[]; count: number}; error?: string; loading: boolean} => {
  const [loading, setLoading] = useState<boolean>(true);
  const dataProvider = useDataProvider();
  const [data, setData] = useState<{items: T[]; count: number}>();
  const [error, setError] = useState<string>();

  const prepareParams = (params: {[key: string]: string | number}) => {
    return Object.keys(params)
      .map((key: string) => `${key}=${params[key]}`)
      .join('&');
  };

  useEffect(() => {
    dataProvider
      .get(`/${resource}` + (params ? '?' + prepareParams(params) : ''))
      .then((result) => setData(result.data))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, [resource, params, time]);

  return useMemo(
    () => ({
      data,
      error,
      loading
    }),
    [data, error, loading]
  );
};

export default useFetchListData;
