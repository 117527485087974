import React, {ReactNode} from 'react';
import {useDialogContext} from 'components/dialog/DialogContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {toast} from 'react-toastify';
import {useDataProvider} from 'provider/DataProvider';

const CreateDialogBody = ({children, onSuccess}: {children: ReactNode; onSuccess?: () => void}) => {
  const {resource} = useResourceContext();
  const dataProvider = useDataProvider();

  const {close} = useDialogContext();

  const handleCreate: SubmitHandler<React.FormEvent<HTMLFormElement>> = (payload) => {
    dataProvider
      .createResource(resource, payload)
      .then((res) => {
        let itemName;
        if (res.data) {
          itemName = res.data.name || res.data.id || '';
        }
        if (itemName) {
          toast.success(`${resource}${itemName} has been created successfully!`);
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
          }
          close();
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  return (
    <FormContainer FormProps={{id: `create_${resource}`, noValidate: true}} onSuccess={handleCreate}>
      {children}
    </FormContainer>
  );
};

export default CreateDialogBody;
