/* eslint-disable react/prop-types */
import React from 'react';
import {QuestionType} from './Survey';
import {Box, Typography} from '@mui/material';
import {Range, getTrackBackground} from 'react-range';

import './Question.css';
import {useController} from 'react-hook-form';

const STEP = 1;
const MIN = 1;
const MAX = 7;

const Question = ({data, disabled}: {data: QuestionType; value: number; disabled: boolean}) => {
  const {
    field: {value, onChange: setValue}
  } = useController({name: data.id.toString()});

  const onChange = (values: number[]) => {
    setValue(values[0]);
  };

  const createScale = () => {
    const scale = [];

    for (let i = MIN; i <= MAX; i = i + STEP) {
      scale.push(
        <span key={i} className="scale_item">
          {i}
        </span>
      );
    }

    return scale;
  };

  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="h5">{data.question}</Typography>
      {/* <Rating name={`question${data.id}`} max={7} defaultValue={value} disabled={disabled} /> */}

      <Range
        values={[value]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={onChange}
        renderTrack={({props, children}) => {
          return (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '30px',
                  width: '100%',
                  background: getTrackBackground({
                    values: [value],
                    colors: ['rgb(230, 192, 179)', 'rgb(102, 99, 106)'],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
                <div className="scale">{createScale()}</div>
              </div>
            </div>
          );
        }}
        renderThumb={({props, isDragged}) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '30px',
              width: '30px',
              borderRadius: '15px',
              backgroundColor: 'rgb(57, 48, 49)',
              color: '#fff',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '3px solid rgb(230, 192, 179)'
            }}
          >
            {value}
          </div>
        )}
      />
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <span>{data.rangeMinLabel}</span>
        <span>{data.rangeMaxLabel}</span>
      </Box>
    </Box>
  );
};

export default Question;
