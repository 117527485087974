import React, {ReactNode} from 'react';
import {useDialogContext} from 'components/dialog/DialogContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import {SubmitHandler} from 'react-hook-form';
import {toast} from 'react-toastify';
import {FormContainer} from 'react-hook-form-mui';
import {useDataProvider} from 'provider/DataProvider';
import {transformDatesAndRelations} from 'services/utils';

const CloneDialogBody = ({children, onSuccess}: {children: ReactNode; onSuccess?: () => void}) => {
  const {resource} = useResourceContext();
  const dataProvider = useDataProvider();
  const {close} = useDialogContext();
  const {record} = useRecordContext();

  const defaults = React.useMemo(() => transformDatesAndRelations(record, resource), [record]);

  const handleCreate: SubmitHandler<React.FormEvent<HTMLFormElement>> = (payload) => {
    dataProvider
      .createResource(resource, payload)
      .then((res) => {
        let itemName;
        if (res.data) {
          itemName = res.data.name || res.data.id || '';
        }
        if (itemName) {
          toast.success(`${resource}${itemName} has been created successfully!`);
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
          }
          close();
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  return (
    <FormContainer FormProps={{id: `clone_${resource}`, noValidate: true}} defaultValues={defaults} onSuccess={handleCreate}>
      {children}
    </FormContainer>
  );
};

export default CloneDialogBody;
