import React, {useState} from 'react';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import QuestionForm from './QuestionForm';
import List from 'components/list/List';
import {ListField} from 'components/list/Fields';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import {RecordType} from 'components/record/RecordContext';
import CreateButton from 'components/forms/CreateButton';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import {useListContext} from 'components/list/ListContext';

export type QuestionType = RecordType & {
  question: string;
};

const QuestionView = () => {
  const {refetch} = useListContext();

  return (
    <List
      hidePagination
      title="Questions"
      toolbar={
        <CreateButton>
          <QuestionForm />
        </CreateButton>
      }
    >
      <ListField source="id" label="ID" />
      <ListField source="question" label="Question" />
      <ActionMenu label="Actions" align="right" width="5%">
        <ActionMenuItemEdit callback={refetch} confirmContent={<QuestionForm />} />
        <ActionMenuItemDelete callback={refetch} />
      </ActionMenu>
    </List>
  );
};

export default QuestionView;
