import React, {createContext, useContext} from 'react';
import axios, {AxiosInstance} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {toast} from 'react-toastify';
import {transformDates} from 'services/utils';

class API {
  private instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
    this.instance.defaults.headers.post['Content-Type'] = 'application/json';

    const token = localStorage.getItem('access_token');
    if (token) {
      this.setAuthorizationHeader(token);
    }

    // const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refreshAuthLogic = async (failedRequest: any) => {
      const inst = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      });
      inst.defaults.headers.post['Content-Type'] = 'application/json';
      const refreshToken = localStorage.getItem('refresh_token');
      console.log(refreshToken);
      return inst
        .post('/getToken', {
          refreshToken
        })
        .then((tokenRefreshResponse) => {
          localStorage.setItem('access_token', tokenRefreshResponse.data.accessToken);
          this.setAuthorizationHeader(tokenRefreshResponse.data.accessToken);
          failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.accessToken;
          return Promise.resolve();
        })
        .catch(() => {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          toast.error("Your session is expired or you don't have permission!");
          // navigate('/login');
        });
    };

    // Instantiate the interceptor
    createAuthRefreshInterceptor(this.instance, refreshAuthLogic);
  }

  public setAuthorizationHeader(header: string) {
    this.instance.defaults.headers.common['Authorization'] = header;
  }

  public removeAuthorizationHeader() {
    delete this.instance.defaults.headers.common['Authorization'];
  }

  public async post(url: string, payload: object) {
    return this.instance.post(url, payload);
  }

  public async put(url: string, payload: object) {
    return this.instance.put(url, payload);
  }

  public async delete(url: string) {
    return this.instance.delete(url);
  }

  public async get(url: string) {
    return this.instance.get(url);
  }

  public async createResource(resource: string, payload: object) {
    return this.post(`/${resource}`, transformDates(payload));
  }

  public async deleteResource(resource: string, id: number) {
    return this.delete(`/${resource}/${id}`);
  }

  public async updateResource(resource: string, id: number, payload: object) {
    return this.put(`/${resource}/${id}`, transformDates(payload));
  }
}

const defaultContext: API = new API();

const DataContext = createContext<API>(defaultContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DataProvider = ({children}: any) => {
  return <DataContext.Provider value={defaultContext}>{children}</DataContext.Provider>;
};

export const useDataProvider = () => {
  return useContext(DataContext);
};

export default DataProvider;
