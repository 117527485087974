import React from 'react';
import {Box} from '@mui/material';
import {TextInput} from 'components/forms/Inputs';

const QuestionForm = () => {
  return (
    <Box sx={{mt: 1}}>
      <TextInput required fullWidth label="Question" name="question" autoFocus />
      <TextInput required fullWidth label="Min Label" name="rangeMinLabel" />
      <TextInput required fullWidth label="Max Label" name="rangeMaxLabel" />
    </Box>
  );
};

export default QuestionForm;
