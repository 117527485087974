import React, {Children} from 'react';

import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {TableHead as MuiTableHead} from '@mui/material';
import TableCell, {SortDirection} from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import {useListContext} from './ListContext';
import {Identifier} from 'components/record/RecordContext';

// import { visuallyHidden } from './utils';

// ----------------------------------------------------------------------

const TableHead = ({order, orderBy, rowCount, children, onRequestSort, bulkOptions, ids}: TableHeadProp) => {
  const {selected, setSelected} = useListContext();
  const onSort = (property: string) => () => {
    onRequestSort(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (!checked) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  return (
    <MuiTableHead>
      <TableRow>
        {bulkOptions && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < rowCount}
              checked={rowCount > 0 && selected.length === rowCount}
              onChange={() => {
                handleSelectAllClick(rowCount > 0 && selected.length === rowCount);
              }}
            />
          </TableCell>
        )}

        {Children.map(children, (field, index) => {
          if (field) {
            if (field.props && 'source' in field.props && 'label' in field.props) {
              return (
                <TableCell
                  key={index}
                  align={field.props?.align || 'left'}
                  sortDirection={orderBy === field.props.source ? order : false}
                  style={{width: field.props?.width}}
                >
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === field.props.source}
                    direction={orderBy === field.props.source && order ? order : 'asc'}
                    onClick={onSort(field.props.source)}
                  >
                    {field.props.label}
                    {/* {orderBy === headCell.id ? <Box sx={{...visuallyHidden}}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null} */}
                  </TableSortLabel>
                </TableCell>
              );
            }
            return (
              <TableCell key={index} align={field?.props?.align || 'left'} style={{width: field?.props?.width}}>
                {field?.props.label || ''}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </MuiTableHead>
  );
};

type HeadLabel = {
  id: string;
  align?: 'center' | 'left' | 'right' | 'justify' | 'inherit';
  width?: string;
  minWidth?: string;
  label?: string;
};

type TableHeadProp = {
  order: SortDirection;
  orderBy: string;
  rowCount: number;
  headLabel?: Array<HeadLabel>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onRequestSort: Function;
  bulkOptions?: boolean;
  children?: JSX.Element | JSX.Element[];
  ids: Identifier[];
};

export default TableHead;
