import React, {useState} from 'react';
import {ContentCopy as ContentCopyIcon} from '@mui/icons-material';
import ActionMenuItem, {ActionMenuItemProps} from './ActionMenuItem';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import CustomDialog from 'components/forms/CustomDialog';
import {Button} from '@mui/material';
import {DialogContextProvider} from 'components/dialog/DialogContext';
import CloneDialogBody from 'components/forms/CloneDialogBody';

const ActionMenuItemClone = (props: ActionMenuItemCloneProps): JSX.Element | null => {
  const {resource} = useResourceContext();
  const record = useRecordContext();

  const {
    label = 'Clone',
    confirmTitle = `Clone ${resource} item #${record.id}`,
    confirmContent,
    sx,
    icon = <ContentCopyIcon />,
    onSuccess,
    onClose
  } = props;

  const [open, setOpen] = useState(false);

  const handleDialogOpen: React.MouseEventHandler = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  return (
    <React.Fragment>
      <ActionMenuItem onClick={handleDialogOpen} label={label} sx={sx} icon={icon} />
      <DialogContextProvider>
        <CustomDialog
          dialogTitle={confirmTitle}
          primaryButton={
            <Button type="submit" form={`clone_${resource}`} variant="contained">
              Save
            </Button>
          }
          open={open}
          resetDialog={handleClose}
        >
          <CloneDialogBody onSuccess={onSuccess}>{confirmContent}</CloneDialogBody>
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

export default ActionMenuItemClone;

type ActionMenuItemCloneProps = ActionMenuItemProps & {
  confirmTitle?: React.ReactNode;
  confirmContent: React.ReactNode;
  onClose?: () => void;
};
