import React from 'react';
import {Button, Grid, Typography} from '@mui/material';
import ButtonDialog from 'components/forms/ButtonDialog';
import ChangePasswordDialogBody from 'components/ChangePasswordDialogBody';

const Profile = () => {
  const userName: string = React.useMemo(() => {
    return localStorage.getItem('name') || '';
  }, []);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h3">
        Profile
      </Typography>
      <Grid container>
        <Grid item xs={3} sx={{textAlign: 'right', paddingRight: 1}}>
          name:
        </Grid>
        <Grid item xs={9}>
          {userName}
        </Grid>
        <Grid item xs={12}>
          <ButtonDialog
            buttonLabel="Change Password"
            dialogTitle="Change Password"
            primaryButton={
              <Button type="submit" form="change-password" variant="contained">
                save
              </Button>
            }
          >
            <ChangePasswordDialogBody />
          </ButtonDialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Profile;
