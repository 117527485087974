import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  SortDirection,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography
} from '@mui/material';
import TableHead from './TableHead';
import {Identifier, RecordContextProvider, RecordType} from 'components/record/RecordContext';
import TableRow from './TableRow';
import useFetchListData from 'hooks/useFetchListData';
import {useResourceContext} from 'components/record/ResourceContext';
import {ListContextProvider, useListContext} from './ListContext';
import {Sync} from '@mui/icons-material';
import TableToolbar from './actionMenu/TableToolbar';

const ListBody = <T extends RecordType>(props: ListProps<T> & {time: number; refetch: () => void}) => {
  const {
    children,
    bulkOptions = false,
    hidePagination = false,
    title,
    refresh = false,
    avatar,
    onChangeSelected,
    bulkActions,
    toolbar = <React.Fragment></React.Fragment>,
    listFilters = <React.Fragment></React.Fragment>,
    identifier = 'id',
    time,
    refetch,
    defaultOrderBy = 'id',
    defaultOrder = 'asc'
  } = props;

  const {resource, params} = useResourceContext();
  const {selected} = useListContext();

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<SortDirection>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const variables = React.useMemo(
    () => ({
      ...params,
      orderBy, //TODO:
      order: order === 'asc' ? 'asc' : 'desc',
      ...(!hidePagination ? {page: page, size: rowsPerPage} : {})
    }),
    [page, rowsPerPage, orderBy, order, hidePagination, params]
  );

  const {error, data, loading} = useFetchListData<T>(resource, variables, time);

  const list = React.useMemo(() => (data?.items || []).map((item: T) => ({...item, id: item[identifier]})), [data]);
  const count = React.useMemo(() => data?.count || data?.items?.length || 0, [data]);

  React.useEffect(() => {
    if (typeof onChangeSelected === 'function') {
      onChangeSelected(selected);
    }
  }, [selected]);

  const handleSort = (id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) return <>Error</>;

  return (
    <Card sx={{p: 2, mb: 2, position: 'relative'}}>
      <CardHeader
        avatar={avatar}
        title={
          title && (
            <Typography component="div" variant="h5">
              {title}
            </Typography>
          )
        }
        action={
          <Box gap={2} display="flex" alignItems="center">
            {toolbar}
            {refresh && (
              <IconButton onClick={() => refetch()} color="primary">
                <Sync />
              </IconButton>
            )}
          </Box>
        }
      />
      {listFilters}
      {bulkActions && <TableToolbar onChangeSelected={onChangeSelected} bulkActions={bulkActions} />}
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table sx={{width: '100%'}} size="small">
          {React.createElement(
            TableHead,
            {
              bulkOptions,
              order,
              orderBy,
              rowCount: count,
              onRequestSort: handleSort,
              ids: list.map((item) => item.id)
            },
            children
          )}

          <TableBody>
            {list?.map((row) => (
              <RecordContextProvider key={row.id} value={row}>
                <TableRow bulkOptions={bulkOptions} id={row.id}>
                  {children}
                </TableRow>
              </RecordContextProvider>
            ))}

            {/* <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, users.length)} /> */}

            {/* {users.length === 0 && <TableNoData query={filterName} />} */}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          page={page}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Card>
  );
};

const List = <T extends RecordType>(props: ListProps<T>) => {
  const {selected} = props;
  const [time, setTime] = React.useState<number>(new Date().getTime());
  const [actionFilters, setActionFilters] = React.useState({});

  const refetch = () => {
    setTime(new Date().getTime());
  };

  const addFilters = (filters: ListFiltersType) => {
    setActionFilters((actionFilters) => ({...actionFilters, ...filters}));
  };

  return (
    <ListContextProvider refetch={refetch} filters={actionFilters} addFilters={addFilters} defaultSelected={selected}>
      <ListBody {...props} time={time} refetch={refetch} />
    </ListContextProvider>
  );
};

export default List;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListFiltersType = any;

type ListProps<T extends RecordType> = {
  toolbar?: React.ReactElement;
  avatar?: React.ReactNode;
  title?: React.ReactNode;
  refresh?: boolean;
  children: JSX.Element | JSX.Element[];
  filters?: ListFiltersType;
  listFilters?: React.ReactNode;
  list?: T[];
  onEditClick?: (record: T) => void;
  onDeleteClick?: (record: T) => void;
  hidePagination?: boolean;
  selected?: Identifier[];
  onChangeSelected?: (selected: Identifier[]) => void;
  bulkOptions?: boolean;
  bulkActions?: React.ReactNode;
  identifier?: string;
  defaultOrderBy?: string;
  defaultOrder?: SortDirection;
};
