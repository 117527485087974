import React, {useEffect, useMemo, useState} from 'react';
import {Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from '@mui/material';
import {QuestionType} from 'sections/question/QuestionView';
import {Identifier} from 'components/record/RecordContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const SelectQuestion = ({existing, all, values, onChange}: SelectQuestionProps) => {
  const [questions, setQuestions] = useState<QuestionType[]>([]);

  const existingIds = useMemo(() => existing.map((item) => item.id), [existing]);
  const questionsValues: {[key: Identifier]: string} = useMemo(
    () => questions.reduce((acc, question) => ({...acc, [question.id]: question.question}), {}),
    [questions]
  );

  useEffect(() => {
    setQuestions(all.filter((item) => !existingIds.includes(item.id)));
  }, [all]);

  return (
    <FormControl sx={{m: 1, width: '100%'}}>
      <InputLabel id="questions-multiple-checkbox-label">Questions</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        name="questions"
        value={values}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label="Questions" />}
        renderValue={(selected: Identifier[]) => (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected
              .map((item) => questionsValues[item])
              .map((value) => (
                <Chip key={value} label={value} />
              ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {questions.map((question) => (
          <MenuItem key={question.id} value={question.id}>
            <Checkbox checked={values.indexOf(question.id) > -1} />
            <ListItemText primary={question.question} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectQuestion;

type SelectQuestionProps = {
  existing: QuestionType[];
  all: QuestionType[];
  values: Identifier[];
  onChange: (event: SelectChangeEvent<Identifier[]>) => void;
};
