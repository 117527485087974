import React from 'react';
import {useDialogContext} from 'components/dialog/DialogContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {toast} from 'react-toastify';
import {useRecordContext} from 'components/record/useRecordContext';
import {useDataProvider} from 'provider/DataProvider';
import {Button} from '@mui/material';

const DeleteConfirmButton = ({onSuccess}: {onSuccess?: () => void}) => {
  const {resource} = useResourceContext();
  const dataProvider = useDataProvider();

  const {close} = useDialogContext();
  const {id} = useRecordContext();

  const handleDelete = () => {
    dataProvider
      .deleteResource(resource, id)
      .then((res) => {
        let itemName;
        if (res.data) {
          itemName = res.data.name || res.data.id || '';
        }
        if (itemName) {
          toast.success(`${resource}${itemName} has been deleted successfully!`);
          close();
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  return (
    <Button variant="contained" onClick={handleDelete}>
      Delete
    </Button>
  );
};

export default DeleteConfirmButton;
