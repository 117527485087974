import React, {useState} from 'react';
import {Edit as EditIcon} from '@mui/icons-material';
import ActionMenuItem, {ActionMenuItemProps} from './ActionMenuItem';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import CustomDialog from 'components/forms/CustomDialog';
import {Button} from '@mui/material';
import {DialogContextProvider} from 'components/dialog/DialogContext';
import EditDialogBody from 'components/forms/EditDialogBody';

const ActionMenuItemEdit = (props: ActionMenuItemEditProps): JSX.Element | null => {
  const {resource} = useResourceContext();
  const record = useRecordContext();

  const {label = 'Edit', confirmTitle = `Edit ${resource} item #${record.id}`, confirmContent, sx, icon = <EditIcon />, onSuccess, onClose} = props;

  const [open, setOpen] = useState(false);

  const handleDialogOpen: React.MouseEventHandler = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  return (
    <React.Fragment>
      <ActionMenuItem onClick={handleDialogOpen} label={label} sx={sx} icon={icon} />
      <DialogContextProvider>
        <CustomDialog
          dialogTitle={confirmTitle}
          primaryButton={
            <Button type="submit" form={`edit_${resource}`} variant="contained">
              Save
            </Button>
          }
          open={open}
          resetDialog={handleClose}
        >
          <EditDialogBody onSuccess={onSuccess}>{confirmContent}</EditDialogBody>
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

export default ActionMenuItemEdit;

type ActionMenuItemEditProps = ActionMenuItemProps & {
  confirmTitle?: React.ReactNode;
  confirmContent: React.ReactNode;
  onClose?: () => void;
};
