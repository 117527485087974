import React from 'react';
import {Box, Typography} from '@mui/material';
import {QuestionsTable} from './QuestionsTable';
import {DatePickerInput} from 'components/forms/Inputs';

const SurveyForm = () => {
  return (
    <Box sx={{mt: 1}}>
      <DatePickerInput required fullWidth label="Day" name="day" autoFocus format="YYYY-MM-DD" />
      <Typography component="div" variant="h5">
        Questions
      </Typography>
      <QuestionsTable />
    </Box>
  );
};

export default SurveyForm;
