import React from 'react';

export const ResourceContext = React.createContext<ResourceContextValue>({resource: '', params: {}});

export type ResourceContextValue = {
  resource: string;
  params: ParamsType;
};

export const ResourceContextProvider = ({children, resource, params = {}}: ResourceContextProviderProps) => (
  <ResourceContext.Provider value={{resource, params}}>{children}</ResourceContext.Provider>
);

export interface ResourceContextProviderProps {
  children: React.ReactNode;
  resource: string;
  params?: ParamsType;
}

export const useResourceContext = (): ResourceContextValue => {
  const context = React.useContext<ResourceContextValue>(ResourceContext);
  return context;
};

export const Resource = ({component, resource}: ResourceProps) => {
  return <ResourceContextProvider resource={resource}>{component}</ResourceContextProvider>;
};

type ParamsType = {
  [key: string]: string | number | boolean;
};

interface ResourceProps {
  component: JSX.Element;
  resource: string;
}
