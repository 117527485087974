import React, {useState} from 'react';
import {Delete as DeleteIcon} from '@mui/icons-material';
import ActionMenuItem, {ActionMenuItemProps} from './ActionMenuItem';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import CustomDialog from 'components/forms/CustomDialog';
import {DialogContextProvider} from 'components/dialog/DialogContext';

const ActionMenuItemConfirmDialog = (props: ActionMenuItemConfirmDialogProps) => {
  const {resource} = useResourceContext();
  const record = useRecordContext();

  const {
    label = 'Delete',
    confirmTitle = `Delete ${resource} item #${record.id}`,
    confirmContent = 'Are you sure you want to delete this item?',
    onClose,
    primaryButton,
    sx,
    icon = <DeleteIcon />
  } = props;

  const [open, setOpen] = useState(false);

  const handleDialogOpen: React.MouseEventHandler = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  return (
    <React.Fragment>
      <ActionMenuItem onClick={handleDialogOpen} label={label} sx={sx} icon={icon} />
      <DialogContextProvider>
        <CustomDialog dialogTitle={confirmTitle} primaryButton={primaryButton} open={open} resetDialog={handleClose}>
          {confirmContent}
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

export default ActionMenuItemConfirmDialog;

export type ActionMenuItemConfirmDialogProps = ActionMenuItemProps & {
  confirmContent?: React.ReactNode;
  confirmTitle?: React.ReactNode;
  onClose?: () => void;
  primaryButton?: JSX.Element;
};
