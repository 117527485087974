import React from 'react';

import List from 'components/list/List';
import {BooleanField, ListField} from 'components/list/Fields';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import {RecordType} from 'components/record/RecordContext';
import SurveyForm from './SurveyForm';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import CreateButton from 'components/forms/CreateButton';
import {QuestionType} from 'sections/question/QuestionView';
import {UserType} from 'sections/user/UserView';
import {Lock} from '@mui/icons-material';
import {useListContext} from 'components/list/ListContext';
import ActionMenuItemClone from 'components/list/actionMenu/ActionMenuItemClone';
import ActionMenuSurveyOpenLink from './ActionMenuSurveyOpenLink';

export type SurveyType = RecordType & {
  day: string;
  questions: Array<QuestionType>;
  users: Array<UserType>;
};

const SurveyView = () => {
  const {refetch} = useListContext();

  return (
    <List
      hidePagination
      title="Surveys"
      toolbar={
        <CreateButton>
          <SurveyForm />
        </CreateButton>
      }
      defaultOrderBy="day"
      defaultOrder="desc"
    >
      <ListField source="day" label="Day" />
      <BooleanField source="locked" label="Locked" width="5%">
        <Lock />
      </BooleanField>
      <ActionMenu label="Actions" align="right" width="5%">
        <ActionMenuSurveyOpenLink />
        <ActionMenuItemEdit callback={refetch} confirmContent={<SurveyForm />} />
        <ActionMenuItemClone callback={refetch} confirmContent={<SurveyForm />} />
        <ActionMenuItemDelete callback={refetch} />
      </ActionMenu>
    </List>
  );
};

export default SurveyView;
