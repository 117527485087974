import dayjs from 'dayjs';

const dateFields = ['day', 'birthDate'];

const RelationsAttributes: {[key: string]: Array<string>} = {
  'survey': ['questions', 'users']
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformDatesAndRelations = (record: any, resource: string) => {
  const newRecord = {...record};
  if (!record) {
    return record;
  }
  for (const key of Object.keys(record)) {
    if (dateFields.includes(key) && typeof record[key] === 'string') {
      newRecord[key] = dayjs(record[key]);
    }

    if (RelationsAttributes[resource] && RelationsAttributes[resource].includes(key) && Array.isArray(newRecord[key])) {
      newRecord[key] = newRecord[key].map((item: {id: number}) => item.id);
    }
  }
  return newRecord;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformDates = (payload: any) => {
  const newPayload = {...payload};
  if (!payload) {
    return payload;
  }
  for (const key of Object.keys(payload)) {
    if (dateFields.includes(key)) {
      newPayload[key] = dayjs(payload[key]).format('YYYY-MM-DD');
    }
  }
  return newPayload;
};
