import React from 'react';
import {FormContainer} from 'react-hook-form-mui';
import {TextInput} from './forms/Inputs';
import {useDataProvider} from 'provider/DataProvider';
import {useDialogContext} from './dialog/DialogContext';
import {toast} from 'react-toastify';

const ForgotPasswordDialogBody = () => {
  const dataProvider = useDataProvider();
  const {close} = useDialogContext();

  const handleSave = (payload: {password: string; confirm_password: string}) => {
    dataProvider
      .post('/user/forgot-password', payload)
      .then(() => {
        toast.success('You will receive an email with instruction.');
        close();
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  return (
    <FormContainer FormProps={{id: 'forgot-password', noValidate: true}} onSuccess={handleSave}>
      <TextInput required fullWidth label="email" name="email" autoFocus />
    </FormContainer>
  );
};

export default ForgotPasswordDialogBody;
