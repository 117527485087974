import React, {MouseEventHandler, useMemo, useState} from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import {Box, Button, CircularProgress, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {Add, Delete, Reorder} from '@mui/icons-material';
import {QuestionType} from 'sections/question/QuestionView';
import {Identifier} from 'components/record/RecordContext';
import ButtonDialog from 'components/forms/ButtonDialog';
import SelectQuestion from './SelectQuestion';
import useFetchListData from 'hooks/useFetchListData';
import {useController} from 'react-hook-form';

export const QuestionsTable = () => {
  const {
    field: {value, onChange}
  } = useController({name: 'questions'});

  const [newValues, setNewValues] = useState<Identifier[]>([]);

  const {data, loading} = useFetchListData<QuestionType>('question');
  const questionsValues: {[key: Identifier]: QuestionType} = useMemo(
    () => (data?.items || []).reduce((acc, question) => ({...acc, [question.id]: question}), {}),
    [data]
  );

  const localItems = React.useMemo(() => (value || []).map((id: Identifier) => questionsValues[id]), [value, questionsValues]);

  // normally one would commit/save any order changes via an api call here...
  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const temp = [...value];
    if (result.destination) {
      const d = temp[result.destination.index];
      temp[result.destination.index] = temp[result.source.index];
      temp[result.source.index] = d;
    }
    onChange(temp);
  };

  const removeItem = (id: Identifier) => {
    onChange((value || []).filter((item: number) => item !== id));
  };

  const handleChange = (event: SelectChangeEvent<Identifier[]>) => {
    const {
      target: {value}
    } = event;
    setNewValues(typeof value === 'string' ? value.split(',').map((i) => parseInt(i)) : value);
  };

  const handleAddQuestion: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const ids = newValues.filter((id) => !(value || []).includes(id));
    onChange([...(value || []), ...ids]);
    setNewValues([]);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer>
      <Table>
        <colgroup>
          <col style={{width: '10%'}} />
          <col style={{width: '80%'}} />
          <col style={{width: '10%'}} />
        </colgroup>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {localItems.map((item: QuestionType, index: number) => (
                  <Draggable key={item.id} draggableId={'item' + item.id} index={index}>
                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                      return (
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none'
                          }}
                        >
                          {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                          <TableCell align="left">
                            <div {...draggableProvided.dragHandleProps}>
                              <Reorder />
                              <input type="hidden" name="array_questions" value={item.id} />
                            </div>
                          </TableCell>
                          <TableCell>{item.question}</TableCell>
                          <TableCell align="right">
                            <Button onClick={() => removeItem(item.id)}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      <ButtonDialog
        buttonLabel="Add Question"
        variant="text"
        startIcon={<Add />}
        dialogTitle="Add Question"
        maxWidth="sm"
        primaryButton={
          <Button onClick={handleAddQuestion} variant="contained">
            Add
          </Button>
        }
      >
        <Box>
          <SelectQuestion existing={localItems} all={data?.items || []} values={newValues} onChange={handleChange} />
        </Box>
      </ButtonDialog>
    </TableContainer>
  );
};
