import React from 'react';

import {TableRow as MuiTableRow} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import {Identifier} from 'components/record/RecordContext';
import {useListContext} from './ListContext';

const TableRow = ({id, children, bulkOptions = false}: TableRowProps) => {
  const {selected, setSelected} = useListContext();

  const isSelect = React.useMemo(() => selected.indexOf(id) !== -1, [selected, id]);

  const handleClick = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => prev.filter((item) => item !== id));
    }
  };

  return (
    <MuiTableRow hover tabIndex={-1} role="checkbox" selected={isSelect}>
      {bulkOptions && (
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={isSelect} onChange={handleClick} />
        </TableCell>
      )}
      {children}
    </MuiTableRow>
  );
};

type TableRowProps = {
  id: Identifier;
  children: JSX.Element | JSX.Element[];
  handleClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  selected?: boolean;
  bulkOptions?: boolean;
};

export default TableRow;
