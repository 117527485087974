import React from 'react';
import './App.css';
import DataProvider from './provider/DataProvider';
import Routes from 'routes';
import {ThemeProvider, createTheme} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultTheme = createTheme();

function App() {
  return (
    <DataProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={defaultTheme}>
          <Routes />
          <ToastContainer position="top-right" autoClose={3000} theme="light" />
        </ThemeProvider>
      </LocalizationProvider>
    </DataProvider>
  );
}

export default App;
