import React from 'react';
import {useRecordContext} from 'components/record/useRecordContext';
import {MenuItem} from '@mui/material';

const ActionMenuSurveyOpenLink = (): JSX.Element | null => {
  const {record} = useRecordContext();

  return (
    <React.Fragment>
      <MenuItem href={`/${record.day}`} target="_blank" component="a">
        Open Survey
      </MenuItem>
    </React.Fragment>
  );
};

export default ActionMenuSurveyOpenLink;
