import React from 'react';
import {useResourceContext} from './ResourceContext';
import {RecordContextProvider, RecordType} from './RecordContext';
import useFetchData from 'hooks/useFetchData';
import {useParams} from 'react-router-dom';
import {CircularProgress} from '@mui/material';

const GetRecord = <T extends RecordType>({children}: GetRecordProps) => {
  const {resource} = useResourceContext();
  const {id} = useParams();
  const {data, isLoading} = useFetchData<T>(`/${resource}/${id}`);

  if (isLoading) {
    return <CircularProgress />;
  }

  return <RecordContextProvider value={data as T}>{children}</RecordContextProvider>;
};

export default GetRecord;

type GetRecordProps = {
  children: React.ReactNode;
};
