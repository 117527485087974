import {Breakpoint, Button, ButtonProps} from '@mui/material';
import React, {ReactNode} from 'react';
import CustomDialog from './CustomDialog';
import {DialogContextProvider} from 'components/dialog/DialogContext';

const ButtonDialog = (props: ButtonDialogProps) => {
  const {
    children,
    buttonLabel = '',
    variant = 'contained',
    size = 'small',
    dialogTitle,
    maxWidth = 'md',
    primaryButton,
    secondaryButton,
    onClick,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (typeof onClick === 'function') {
      onClick(() => setOpen(true));
    } else {
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Button size={size} variant={variant} {...rest} onClick={handleOpen}>
        {buttonLabel}
      </Button>
      <DialogContextProvider>
        <CustomDialog
          maxWidth={maxWidth}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          dialogTitle={dialogTitle}
          open={open}
          resetDialog={() => setOpen(false)}
        >
          {children}
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

type ButtonDialogProps = Omit<ButtonProps, 'onClick'> & {
  children: ReactNode;
  buttonLabel?: string;
  dialogTitle?: string;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  maxWidth?: false | Breakpoint;
  onClick?: (callback: () => void) => void;
};

export default ButtonDialog;
