import dayjs from 'dayjs';
import React from 'react';
import {
  AutocompleteElement,
  AutocompleteElementProps,
  DatePickerElement,
  DatePickerElementProps,
  DateTimePickerElement,
  DateTimePickerElementProps,
  FieldValues,
  SelectElement,
  SelectElementProps,
  SwitchElement,
  SwitchElementProps,
  TextFieldElement,
  TextFieldElementProps
} from 'react-hook-form-mui';

export const TextInput = (props: TextFieldElementProps) => <TextFieldElement margin="normal" {...props} />;

export const SelectInput = (props: SelectElementProps<FieldValues>) => <SelectElement margin="normal" {...props} />;

export const SwitchInput = (props: SwitchElementProps<FieldValues>) => <SwitchElement sx={{width: '100%'}} {...props} />;

export const DateTimePickerInput = <TInputDate,>(props: DateTimePickerElementProps<FieldValues, TInputDate, TInputDate> & {fullWidth?: boolean}) => {
  const {fullWidth = false, ...rest} = props;
  return <DateTimePickerElement inputProps={{margin: 'normal', fullWidth}} defaultValue={Date.now()} {...rest} />;
};

export const DatePickerInput = <TInputDate,>(props: DatePickerElementProps<FieldValues, TInputDate, TInputDate> & {fullWidth?: boolean}) => {
  const {fullWidth = false, ...rest} = props;
  return <DatePickerElement inputProps={{margin: 'normal', fullWidth}} defaultValue={dayjs()} {...rest} />;
};

export const AutocompleteInput = <T,>(props: AutocompleteInputProps<T>) => {
  const {fullWidth = false, ...rest} = props;
  return <AutocompleteElement matchId autocompleteProps={{fullWidth}} textFieldProps={{margin: 'normal'}} {...rest} />;
};

export type AutocompleteInputProps<T> = AutocompleteElementProps<FieldValues, T, boolean, boolean> & {fullWidth?: boolean};
