import React from 'react';
import {Box} from '@mui/material';
import {TextInput, DatePickerInput} from 'components/forms/Inputs';

const UserForm = () => {
  return (
    <Box sx={{mt: 1}}>
      <TextInput margin="normal" required fullWidth id="firstName" label="First Name" name="firstName" autoFocus />
      <TextInput margin="normal" required fullWidth id="lastName" label="Last Name" name="lastName" />
      <TextInput margin="normal" required fullWidth id="email" label="Email" name="email" />
      <DatePickerInput required fullWidth label="Birth Date" name="birthDate" format="YYYY-MM-DD" />
    </Box>
  );
};

export default UserForm;
