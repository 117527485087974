import React from 'react';
import {useParams} from 'react-router-dom';
import useFetchData from 'hooks/useFetchData';
import {CircularProgress} from '@mui/material';
import Survey, {SurveyType} from './Survey';

const Main = () => {
  const {id} = useParams();
  const {data, isLoading, error} = useFetchData<SurveyType[]>(`/survey/find/${id}`);
  if (isLoading) {
    return <CircularProgress />;
  }

  return <>{data?.map((survey) => <Survey key={survey.id} data={survey} />)}</>;
};

export default Main;
