import React from 'react';

import UserForm from './UserForm';
import List from 'components/list/List';
import {ListField} from 'components/list/Fields';
import {RecordType} from 'components/record/RecordContext';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import CreateButton from 'components/forms/CreateButton';
import {useListContext} from 'components/list/ListContext';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';

export type UserType = RecordType & {
  firstName: string;
  lastName: string;
  birthDate: string;
};

const UserView = () => {
  const {refetch} = useListContext();

  return (
    <List
      hidePagination
      title="Users"
      toolbar={
        <CreateButton>
          <UserForm />
        </CreateButton>
      }
    >
      <ListField source="id" label="ID" />
      <ListField source="firstName" label="First Name" />
      <ListField source="lastName" label="Last Name" />
      <ListField source="birthDate" label="Birth Date" />
      <ListField source="roleName" label="Role" />
      <ActionMenu label="Actions" align="right" width="5%">
        <ActionMenuItemEdit callback={refetch} confirmContent={<UserForm />} />
        <ActionMenuItemDelete callback={refetch} />
      </ActionMenu>
    </List>
  );
};

export default UserView;
