import {ResourceContextProvider} from 'components/record/ResourceContext';
import React from 'react';
import {Outlet} from 'react-router';

const ReportResource = () => {
  return (
    <ResourceContextProvider resource="report">
      <Outlet />
    </ResourceContextProvider>
  );
};

export default ReportResource;
