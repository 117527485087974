import React from 'react';
import {FormContainer} from 'react-hook-form-mui';
import {TextInput} from './forms/Inputs';
import {useDataProvider} from 'provider/DataProvider';
import {useDialogContext} from './dialog/DialogContext';
import {toast} from 'react-toastify';

const ChangePasswordDialogBody = () => {
  const dataProvider = useDataProvider();
  const {close} = useDialogContext();

  const handleSave = (payload: {password: string; confirm_password: string}) => {
    if (payload.password === payload.confirm_password) {
      dataProvider
        .post('/user/change-password', payload)
        .then(() => {
          toast.success('Your password was changed successful.');
          close();
        })
        .catch((error) => {
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <FormContainer FormProps={{id: `change-password`, noValidate: true}} onSuccess={handleSave}>
      <TextInput required fullWidth type="password" label="Password" name="password" autoFocus />
      <TextInput required fullWidth type="password" label="Confirm Password" name="confirm_password" />
    </FormContainer>
  );
};

export default ChangePasswordDialogBody;
