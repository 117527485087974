import {isAxiosError} from 'axios';
import {useDataProvider} from 'provider/DataProvider';
import {useEffect, useMemo, useState} from 'react';

const useFetchData = <T,>(url: string, params?: {[key: string]: string | number}): {data?: T; error?: string; isLoading: boolean} => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dataProvider = useDataProvider();
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();

  const prepareParams = (params: {[key: string]: string | number}) => {
    return Object.keys(params)
      .map((key: string) => `${key}=${params[key]}`)
      .join('&');
  };

  useEffect(() => {
    dataProvider
      .get(url + (params ? '?' + prepareParams(params) : ''))
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }, [url, params]);

  return useMemo(
    () => ({
      data,
      error,
      isLoading
    }),
    [data, error, isLoading]
  );
};

export default useFetchData;
