import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {ProtectedRoute} from './ProtectedRoute';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Dashboard from 'pages/Dashboard';
import Home from 'pages/Home';
import UserView from 'sections/user/UserView';
import QuestionView from 'sections/question/QuestionView';
import SurveyView from 'sections/survey/SurveyView';
import Admin from 'pages/Admin';
import Main from 'sections/main/Main';
import {Resource} from 'components/record/ResourceContext';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import ReportPreview from 'sections/report/ReportPreview';
import GetRecord from 'components/record/GetRecord';
import ReportResource from 'sections/report/ReportResource';
import ReportsList from 'sections/report/ReportsList';

const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: '/service',
      element: <div>Service Page</div>
    },
    {
      path: '/about-us',
      element: <div>About Us</div>
    }
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/admin',
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '/admin',
          element: <Admin />,
          children: [
            // {
            //   path: '/admin/',
            //   element: <Dashboard />
            // },
            {
              path: '/admin/users',
              element: <Resource component={<UserView />} resource="user" />
            },
            {
              path: '/admin/questions',
              element: <Resource component={<QuestionView />} resource="question" />
            },
            {
              path: '/admin/surveys',
              element: <Resource component={<SurveyView />} resource="survey" />
            },
            {
              path: '/admin/reports',
              element: <ReportResource />,
              children: [
                {
                  path: '/admin/reports',
                  element: <ReportsList />
                },
                {
                  path: '/admin/reports/:id',
                  element: (
                    <GetRecord>
                      <ReportPreview />
                    </GetRecord>
                  )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <Home />,
      children: [
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/profile',
          element: <Profile />
        },
        {
          path: '/',
          element: <Main />
        },
        {
          path: '/:id',
          element: <Main />
        }
      ]
    }
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/reset/:str',
      element: <ResetPassword />
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...routesForPublic, ...routesForNotAuthenticatedOnly, ...routesForAuthenticatedOnly]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
