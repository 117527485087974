import React from 'react';

import List from 'components/list/List';
import {LinkField} from 'components/list/Fields';
import {RecordType} from 'components/record/RecordContext';
import {QuestionType} from 'sections/question/QuestionView';
import {UserType} from 'sections/user/UserView';
export type ReportType = RecordType & {
  day: string;
  questions: Array<QuestionType>;
  users: Array<UserType>;
};

const ReportsList = () => {
  return (
    <List hidePagination title="Reports" defaultOrderBy="day" defaultOrder="desc">
      <LinkField source="day" label="Day" link={(record) => `/admin/reports/${record?.id}`} />
    </List>
  );
};

export default ReportsList;
