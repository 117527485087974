import {Box, Button, CssBaseline, Grid, Paper, Typography} from '@mui/material';
import React, {useMemo, useState} from 'react';
import Question from './Question';
import {useDataProvider} from 'provider/DataProvider';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {TextInput} from 'components/forms/Inputs';
import {toast} from 'react-toastify';

export type QuestionType = {id: number; question: string; rangeMinLabel: string; rangeMaxLabel: string};

export type SurveyType = {
  id: number;
  day: string;
  locked: boolean;
  questions: Array<QuestionType>;
};

export type AnswerType = {
  value: number;
  questionId: number;
};

const Survey = ({data}: {data: SurveyType}) => {
  const answersList: Array<AnswerType> = [];

  const [submitted, setSubmitted] = useState<boolean>(!!localStorage.getItem(data.day));

  const answers: {[key: number]: number} = useMemo(
    () => (answersList || []).reduce((acc, answer) => ({...acc, [answer.questionId]: answer.value}), {}),
    [answersList]
  );

  const defaultValues = useMemo(() => {
    const values: {[key: string]: string} = data.questions.reduce((acc, question) => ({...acc, [question.id]: answers[question.id] || 1}), {});
    values.name = localStorage.getItem('user_name') || '';
    return values;
  }, [answers]);

  const dataProvider = useDataProvider();

  const handleSubmit: SubmitHandler<React.FormEvent<HTMLFormElement>> = (payload) => {
    localStorage.setItem('user_name', (payload as unknown as {name: string}).name);
    dataProvider.post(`/survey/${data.id}/answer`, payload).then((result) => {
      toast.success('Your answers have been sent successfully.');
      setSubmitted(true);
      localStorage.setItem(data.day, 'true');
      console.log(result.data);
    });
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center'
      }}
    >
      <CssBaseline />
      <Grid item xs={12} sm={8} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h2">
            {data.day}
          </Typography>
          {!submitted && (
            <FormContainer onSuccess={handleSubmit} FormProps={{style: {width: '100%'}}} defaultValues={defaultValues}>
              <TextInput required fullWidth label="Name" name="name" autoFocus />
              {data.questions.map((question) => (
                <Question key={question.id} data={question} value={answers[question.id] || 0} disabled={data.locked} />
              ))}
              <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}} disabled={data.locked}>
                Submit
              </Button>
            </FormContainer>
          )}
          {submitted && (
            <>
              You already submit you answers.
              <Button variant="contained" sx={{mt: 3, mb: 2}} onClick={() => setSubmitted(false)}>
                Change
              </Button>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Survey;
