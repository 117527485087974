import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDataProvider} from 'provider/DataProvider';

const Logout = () => {
  const dataProvider = useDataProvider();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('name');
    dataProvider.removeAuthorizationHeader();
    navigate('/login', {replace: true});
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <>Logout Page</>;
};

export default Logout;
